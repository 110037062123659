import React from "react";
import Providers from "../../providers";

const withProviders = (Component: any) => {
  const withProvidersContainer = ({ data }: any) => (
    <Providers>
      <Component data={data} />
    </Providers>
  );

  return withProvidersContainer;
};

export default withProviders;
