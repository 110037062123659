import React from "react";
import { BrowserRouter } from "react-router-dom";
import LoadingOverlay from "./Components/Layout/Loading/Overlay";
import withProviders from "./hoc/WithProviders";
import { useGlobalContext } from "./hooks/useGlobalContext";
import Rotas from "./routes";

function App() {
  const { loading } = useGlobalContext();

  return (
    <>
      <LoadingOverlay isVisible={loading} />
      <BrowserRouter>
        <Rotas />
      </BrowserRouter>
    </>
  );
}

export default withProviders(App);
