import React, { ComponentType, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout";
import LoadingOverlay from "./Components/Layout/Loading/Overlay";
import { routes } from "./Constants/routes";

const Loadable =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P) =>
    (
      <Suspense fallback={<LoadingOverlay />}>
        <Component {...props} />
      </Suspense>
    );

const Login = Loadable(lazy(() => import("./Pages/Login/View")));

// Ordem de Servico pages
const OrdemDeServicoList = Loadable(
  lazy(() => import("./Pages/OrdemDeServico/List"))
);
const CreateOrdemDeServico = Loadable(
  lazy(() => import("./Pages/OrdemDeServico/Create"))
);
const EditOrdemDeServico = Loadable(
  lazy(() => import("./Pages/OrdemDeServico/Edit"))
);
const ViewOrdemDeServico = Loadable(
  lazy(() => import("./Pages/OrdemDeServico/View"))
);
const OrdemDeServicoCalendar = Loadable(
  lazy(() => import("./Pages/OrdemDeServico/Calendar"))
);

// Ordem de Servico pages
const AvaliacaoList = Loadable(lazy(() => import("./Pages/OsAvaliacao/List")));
const CreateAvaliacao = Loadable(
  lazy(() => import("./Pages/OsAvaliacao/Create"))
);
const AvaliacaoSaved = Loadable(
  lazy(() => import("./Pages/OsAvaliacao/Saved"))
);
const AvaliacaoUnavailable = Loadable(
  lazy(() => import("./Pages/OsAvaliacao/Unavailable"))
);
const ViewAvaliacao = Loadable(lazy(() => import("./Pages/OsAvaliacao/View")));

// Ordem de Servico pages
const NotificacaoList = Loadable(
  lazy(() => import("./Pages/Notificacao/List"))
);

const CreateEquipamento = Loadable(
  lazy(() => import("./Pages/Equipamento/Create"))
);

const EditEquipamento = Loadable(
  lazy(() => import("./Pages/Equipamento/Edit"))
);

const ViewEquipamento = Loadable(
  lazy(() => import("./Pages/Equipamento/View"))
);

const ListEquipamento = Loadable(
  lazy(() => import("./Pages/Equipamento/List"))
);

//Cliente
const ViewCliente = Loadable(lazy(() => import("./Pages/Cliente/View")));

const CreateCliente = Loadable(lazy(() => import("./Pages/Cliente/Create")));

const EditCliente = Loadable(lazy(() => import("./Pages/Cliente/Edit")));

const ListCliente = Loadable(lazy(() => import("./Pages/Cliente/List")));

//Tecnico
const ListTecnico = Loadable(lazy(() => import("./Pages/Tecnico/List")));

const CreateTecnico = Loadable(lazy(() => import("./Pages/Tecnico/Create")));

const EditTecnico = Loadable(lazy(() => import("./Pages/Tecnico/Edit")));

const ViewTecnico = Loadable(lazy(() => import("./Pages/Tecnico/View")));

const Rotas = () => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path={routes.BASE} element={<Layout />}>
        <Route path={routes.OS_LIST} element={<OrdemDeServicoList />} />
        <Route path={routes.OS_CREATE} element={<CreateOrdemDeServico />} />
        <Route path={routes.OS_EDIT(":id")} element={<EditOrdemDeServico />} />
        <Route path={routes.OS_VIEW(":id")} element={<ViewOrdemDeServico />} />
        <Route path={routes.OS_CALENDAR} element={<OrdemDeServicoCalendar />} />
        <Route path={routes.CLIENTE_CADASTRAR} element={<CreateCliente />} />
        <Route path={routes.CLIENTE_LIST} element={<ListCliente />} />
        <Route path={routes.CLIENTE_VIEW(":id")} element={<ViewCliente />} />
        <Route path={routes.CLIENT_EDIT(":id")} element={<EditCliente />} />
        <Route path={routes.NOTIFICACAO_LIST} element={<NotificacaoList />} />
        <Route
          path={routes.OS_AVALIACAO_VIEW(":osId")}
          element={<ViewAvaliacao />}
        />
        <Route path={routes.OS_AVALIACAO_LIST} element={<AvaliacaoList />} />
        <Route
          path={routes.EQUIPAMENTO_CREATE}
          element={<CreateEquipamento />}
        />
        <Route
          path={routes.EQUIPAMENTO_EDIT(":id")}
          element={<EditEquipamento />}
        />
        <Route
          path={routes.EQUIPAMENTO_VIEW(":id")}
          element={<ViewEquipamento />}
        />
        <Route path={routes.EQUIPAMENTO_LIST} element={<ListEquipamento />} />
        <Route path={routes.TECNICO_LIST} element={<ListTecnico />} />
        <Route path={routes.TECNICO_CREATE} element={<CreateTecnico />} />
        <Route path={routes.TECNICO_EDIT(":id")} element={<EditTecnico />} />
        <Route path={routes.TECNICO_VIEW(":id")} element={<ViewTecnico />} />
      </Route>
      <Route
        path={routes.OS_AVALIACAO_CREATE(":osId")}
        element={<CreateAvaliacao />}
      />
      <Route path={routes.OS_AVALIACAO_SAVED} element={<AvaliacaoSaved />} />
      <Route
        path={routes.OS_AVALIACAO_UNAVAILABLE}
        element={<AvaliacaoUnavailable />}
      />
    </Routes>
  );
};

export default Rotas;
