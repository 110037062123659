/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useCallback, useContext, useState } from "react";

type TGlobalContext = {
  loading: boolean;
  setIsLoading: (loading: boolean) => void;
};

const GlobalContext = createContext<TGlobalContext>({} as TGlobalContext);

type TProps = {
  children: React.ReactNode;
};

const GlobalContextProvider = ({ children }: TProps) => {
  const [loading, setLoading] = useState(0);

  const setIsLoading = useCallback((loadingVal: boolean) => {
    if (loadingVal) {
      setLoading((prevState) => prevState + 1);
    } else {
      setLoading((prevState) => (prevState <= 0 ? 0 : prevState - 1));
    }
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        loading: Boolean(loading),
        setIsLoading,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalContext = (): TGlobalContext => useContext(GlobalContext);

export { useGlobalContext, GlobalContextProvider };
