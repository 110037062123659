export const routes = {
  BASE: "/",
  OS_LIST: "/os",
  OS_CREATE: "/os/create",
  OS_EDIT: (id: string = "") => `/os/${id}/edit`,
  OS_VIEW: (id: string = "") => `/os/${id}/view`,
  OS_CALENDAR: "/os/calendar",
  NOTIFICACAO_LIST: "/notificacao",
  OS_AVALIACAO_CREATE: (id: string = "") => `/os/${id}/avaliacao/create`,
  OS_AVALIACAO_VIEW: (id: string = "") => `/os/${id}/avaliacao/view`,
  OS_AVALIACAO_SAVED: `/os/avaliacao/saved`,
  OS_AVALIACAO_UNAVAILABLE: `/os/avaliacao/unavailable`,
  OS_AVALIACAO_LIST: `os/avaliacao`,
  CLIENTE_CADASTRAR: "/cliente/cadastrar",
  CLIENT_EDIT: (id: string = "") => `/cliente/${id}/edit`,
  CLIENTE_LIST: "/cliente/list",
  CLIENTE_VIEW: (id: string = "") => `/cliente/${id}/view`,
  EQUIPAMENTO_CREATE: "/equipamento/create",
  EQUIPAMENTO_EDIT: (id: string = "") => `/equipamento/${id}/edit`,
  EQUIPAMENTO_VIEW: (id: string = "") => `/equipamento/${id}/view`,
  EQUIPAMENTO_LIST: "/equipamentos",
  TECNICO_CREATE: "/tecnico/create",
  TECNICO_EDIT: (id: string = "") => `/tecnico/${id}/edit`,
  TECNICO_VIEW: (id: string = "") => `/tecnico/${id}/view`,
  TECNICO_LIST: "/tecnicos"
};
