const corGeral: any = {
  primaria: "#2F80ED",
  primariaHover: "#0e60b4",
  secundaria: "#FFFFFF",
  secundariaHover: "#EDEDED",
  textoSecundaria: "#2F80ED",
  alerta: "red",
  textoConteudo: "#4F4F4F",
  desabilitado: "#898A8D",
  cinzaClaro: "#F4F4F5",
  inputLogin: `rgba(56, 58, 60, 0.6)`,
  cardhover: `#ffffff4a`,
  themeDarkBackground: `#202123`,
  themeDarkBox: `#343541`,
  themeLightBox: `#EDEBF1`,
  hoverCell: `#e4e2e8`,
  navyBlue: "#001C4E",

  //tela potencias criticas
  criticoP: `#E8463C`,
  atencaoP: `#ebca67`,

  itensSidebar: "#FFFFFF",
  bgItemSidebar: `rgba(255, 255, 255, 0.3)`,
  itensMenuTopo: "#898A8D",

  //legendas tabelas
  alarmesAtivos: `rgba(232, 70, 60, 0.6)`,
  alarmesReconhecidos: "#FBD563",
  dispositivoCritico: `rgba(232, 70, 60, 0.6)`,
  dispositivoAtencao: `#FBD563`,
  potenciaCritica: `#E8463C`,

  //tela overview de Medidor
  cardInfosDisp: `linear-gradient(258.91deg, #F8A42A 34.3%, #FBD563 100%)`,
  iconeCardInfosDisp: `#E8961F`,
  atencao: "#FF4E32",

  //cancelar
  edge: "#808080",
  edgeHover: "#ececec",
  background: "#fffff",
};
const corTelegen: any = {
  background: `linear-gradient(180.26deg, #35317E -38.94%, #30B5B0 122.26%)`,
  primaria: "#2F80ED", // azul
  primariaHover: "#0e60b4", // azul + escuro
  iconeBtnSidebar: "#FFFFFF", //branco
};

const corEnvisia: any = {
  background: "#442D96",
  primaria: "#BFD948",
  primariaHover: "#abc145",
  iconeBtnSidebar: "#FFFFFF",
};

export { corGeral, corTelegen, corEnvisia };
